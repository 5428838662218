import React, { useEffect, useState } from "react";
import SelectClinicBranchModal from "../Modal/SelectClinicBranchModal";
import { useDispatch, useSelector } from "react-redux";
import { _callApi } from "src/Services/baseService";
import { ActionCreators } from "src/actions/profile";
import { getProviderRoles, logoutAndRedirect } from "src/utils/common";
import { getDashboard } from "src/utils";
import { useHistory } from "react-router-dom";
import * as messageClass from "../../common/message";
import _debounce from "lodash/debounce";
import { useCallback } from "react";

const SelectClinicBranchwrapper = (WrappedComponent) => {
  return function WithModalComponent(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const clinicsInfo = useSelector((state) => state.user.clinicsInfo);
    const profile = useSelector((state) => state.user.profile);
    const tabUid = useSelector((state) => state.user.tabUid);
    const sessionDuration = useSelector((state) => state.user.sessionDuration);
    const isCareNavigator = useSelector((state) => state.user.isCareNavigator);
    const [showHideChooseBranch, setShowHideChooseBranch] = useState(false);
    const [clinicIDError, setClinicIDError] = useState(false);
    const [redirect, setRedirect] = useState(true);
    const [clinicID, setClinicID] = useState(null);

    const [clinicBranches] = useState(clinicsInfo);
    const [clinics, setClinics] = useState({
      totalRecords: 0,
      currentPageNumber: 0,
      totalPage: 0,
      data: [],
      isLoading: true,
      hasMore: true,
    });
    const [searchClinicVal, setSearchClinicVal] = useState("");
    const [clinicSelectedConfirm, setClinicSelectedConfirm] = useState(false);

    useEffect(() => {
      if (profile?.email !== undefined && showHideChooseBranch) {
        getClinicsByEmail(profile?.email);
        setClinicSelectedConfirm(false);
      }
    }, [profile?.email, showHideChooseBranch]);

    const handleChooseBranchModalShowHide = () => {
      setShowHideChooseBranch(!showHideChooseBranch);
    };
    const handleBranchChange = (e) => {
      setClinicID(e.target.value);
      setClinicIDError("");
    };
    const setClinicBranch = (e) => {
      e.preventDefault();
      if (clinicID !== null) {
        dispatch(ActionCreators.setClinicBranch(clinicID));
        let currentClinic = clinics?.data?.find(
          (branch) => branch.clinicId === clinicID
        );

        if (currentClinic && Object.keys(currentClinic).length > 0) {
          dispatch(ActionCreators.setClinicTZ(currentClinic.timezone));
          dispatch(
            ActionCreators.setParentClinic(currentClinic.parentClinicId)
          );
          localStorage.setItem("UserTimeZone", currentClinic.timezone);
          localStorage.setItem("UserClinic", clinicID);
          localStorage.setItem(
            "UserParentClinic",
            currentClinic.parentClinicId
          );
          // }

          handleChooseBranchModalShowHide();
          setClinicSelectedConfirm(true);
          getProviderRoles(
            currentClinic.parentClinicId,
            clinicID,
            profile,
            dispatch,
            isCareNavigator
          )
            .then((roles) => {
              if (redirect) {
                history.push(getDashboard(roles));
              }
            })
            .catch((error) => {
              dispatch(ActionCreators.setUserRoles([]));
              logoutAndRedirect(profile, dispatch, tabUid, sessionDuration)
                .then(() => {
                  history.push("/login");
                })
                .catch((error) => {
                  console.error("Logout failed:", error);
                });
            });
        }
      } else {
        setClinicIDError(messageClass.CLINIC_BRANCH_REQUIRED);
      }
    };
    const getClinicsByEmail = (email, searchKey = "") => {
      let URL = "REACT_APP_GET_THERAPIST_URL";
      let tail = `/clinics?user=${encodeURIComponent(profile?.email)}&`;
      if (isCareNavigator) {
        URL = "REACT_APP_CLINIC_CLAIMS";
        tail = `care-navigator/${profile?.careNavigatorId}/clinics?`;
      }
      let search = "";
      if (searchKey !== "") {
        search = `&filter=${searchKey}`;
      }
      var data = {};
      var headerdata = "";
      _callApi(
        data,
        URL,
        headerdata,
        2,
        `${tail}pageNumber=${clinics.currentPageNumber}&pageSize=10${search}`
      )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.pageContent?.length > 0) {
              setClinics({
                ...clinics,
                data: clinics.data.concat(response.data.pageContent),
                isLoading: false,
                totalRecords: response.data.totalRecords,
                totalPage: response.data.totalPage,
                currentPageNumber: clinics.currentPageNumber + 1,
                hasMore:
                  response.data.pageContent.length > 0
                    ? response.data?.pageNo + 1 === response.data?.totalPage
                      ? false
                      : true
                    : false,
              });
            } else {
              setClinics({
                ...clinics,
                isLoading: false,
                hasMore: false,
              });
            }
          }
        })
        .catch((error) => {
          console.log("Error fetching clinics:", error);
          setClinics({ ...clinics, isLoading: false, hasMore: false });
        });
    };
    const fetchMoreclinics = () => {
      setTimeout(() => {
        if (
          clinics.data.length <= clinics.totalRecords &&
          clinics.currentPageNumber < clinics.totalPage
        ) {
          getClinicsByEmail(profile?.email, searchClinicVal);
        } else {
          setClinics({ ...clinics, hasMore: false });
        }
      }, 500);
    };
    const debounceClinics = useCallback(
      _debounce((searchValue) => {
        if (searchValue.length >= 3 || searchValue.length === 0) {
          getClinicsByEmail(profile?.email, searchValue);
        }
      }, 300),
      [profile?.email]
    );
    const searchClinic = (e) => {
      const searchValue = e.target.value.trim();
      if (searchClinicVal === searchValue) return;

      setSearchClinicVal(searchValue);
      setClinics({
        totalRecords: 0,
        currentPageNumber: 0,
        totalPage: 0,
        data: [],
        isLoading: searchValue.length >= 3 || searchValue.length === 0,
        hasMore: false,
      });
      debounceClinics(searchValue);
    };
    return (
      <>
        <WrappedComponent
          {...props}
          openClinicSelectionModal={() => handleChooseBranchModalShowHide()}
          setRedirect={setRedirect}
          clinicSelectedConfirm={clinicSelectedConfirm}
        />
        <SelectClinicBranchModal
          showHide={showHideChooseBranch}
          handleModalShowHide={handleChooseBranchModalShowHide}
          messageConfirm={clinicIDError}
          handleBranchConfirm={setClinicBranch}
          handleOnChangeBranch={handleBranchChange}
          checked={clinicID}
          branches={clinicBranches}
          clinics={clinics}
          fetchMoreclinics={fetchMoreclinics}
          searchClinic={searchClinic}
          searchClinicVal={searchClinicVal}
        />
      </>
    );
  };
};

export default SelectClinicBranchwrapper;
