/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import AWS from "aws-sdk";
import { _callApi } from "src/Services/baseService";
import { ActionCreators } from "src/actions/profile";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProviderRoles, postSessionInfo } from "src/utils/common";
import { getDashboard } from "src/utils";
import Moment from "../../common/UserMoment";
import { v4 as uuidv4 } from "uuid";
import { decrypt, getClientID } from "src/Services/common";
import configureAmplify from "src/Services/amplifyConfig";
import SelectClinicBranchwrapper from "src/components/SelectClinicBranchWrapper";

const IDEAL_TIMEOUT_INIT = Number(process.env.REACT_APP_IDEAL_TIMEOUT_INIT);
const ssoRegion = process.env.REACT_APP_REGION;

AWS.config.update({ region: ssoRegion });

const useQueryParams = () => {
  const location = useLocation();

  // Extract the query string from either location.search or location.hash
  const queryString = location.search
    ? location.search.substring(1) // Remove "?" from search params
    : location.hash.includes("?")
    ? location.hash.split("?")[1] // Extract query params from hash
    : "";

  // Parse query string manually
  const params = {};
  queryString.split("&").forEach((param) => {
    const [key, value] = param.split("=");
    if (key) params[key] = decodeURIComponent(value || ""); // Handle empty values safely
  });

  return params;
};

const SSO = ({
  openClinicSelectionModal,
  setRedirect,
  clinicSelectedConfirm,
}) => {
  let uuid = uuidv4();
  // const location = useLocation();
  const query = useQueryParams();

  const userRoles = useSelector((state) => state.user.userRoles);
  const dispatch = useDispatch();
  const history = useHistory();
  const [qPatDetails, setQPatdetails] = useState({
    patfname: "",
    patlname: "",
    patemail: "",
    patphone: "",
    patmrn: "",
  });
  const [clientRedirectTo, setClientRedirectTO] = useState("dashboard");
  const [clinicList, setClinicList] = useState([]);
  useEffect(async () => {
    const reftoken = query["token"];
    const username = query["sub"];
    const email = query["email"];
    const source = query["source"];
    const patfname = query["patfname"] || "";
    const patlname = query["patlname"] || "";
    const patemail = query["patemail"] || "";
    const patphone = query["patphone"] || "";
    const patmrn = query["patmrn"] || "";
    setQPatdetails({
      patfname,
      patlname,
      patemail,
      patphone,
      patmrn,
    });
    const fetchCDetails = async () => {
      await getClientDetails(email, source, username, reftoken);
    };

    fetchCDetails();
  }, []);
  useEffect(() => {
    if (clinicSelectedConfirm) {
      setRedirection();
    }
  }, [clinicSelectedConfirm]);

  const getClientDetails = async (email, source, username, reftoken) => {
    try {
      const clientData = await getClientID(email?.trim(), source);
      setClientRedirectTO(clientData?.login_redirect || "dashboard");
      const decryptClientId = decrypt(clientData?.tokenTwo, email?.trim());
      const decryptSecHashId = decrypt(clientData?.tokenOne, email?.trim());

      configureAmplify(decryptClientId, "USER_PASSWORD_AUTH"); // Configure Amplify after decrypting client details

      if (decryptClientId && decryptSecHashId) {
        refreshTokens(reftoken, username, decryptClientId, decryptSecHashId);
      }
    } catch (error) {
      console.error("Error fetching client details:", error);
    }
  };

  const computeSecretHash = (username, cId, secHashId) => {
    const crypto = require("crypto");
    const hash = crypto
      .createHmac("sha256", secHashId)
      .update(username + cId)
      .digest("base64");
    return hash;
  };
  const refreshTokens = async (refreshToken, username, clientId, secHash) => {
    await signOutFromCognito(clientId);
    // Clear all storage to ensure clean state
    clearAllAuthStorage();

    const cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider();

    const params = {
      AuthFlow: "REFRESH_TOKEN_AUTH",
      ClientId: clientId,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
        SECRET_HASH: computeSecretHash(username, clientId, secHash),
      },
    };

    try {
      const data = await cognitoIdentityServiceProvider
        .initiateAuth(params)
        .promise();
      var accesstoken = data.AuthenticationResult.AccessToken;
      var idToken = data.AuthenticationResult.IdToken;
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("accessToken", accesstoken);
      localStorage.setItem("UserTimeZone", null);
      localStorage.setItem("UserClinic", null);
      localStorage.setItem("UserParentClinic", null);
      getUserAttributes(data.AuthenticationResult.AccessToken);
    } catch (err) {
      console.error("Error refreshing tokens:", err);
      window.location.href = "/";
    }
  };
  // Function to sign out from AWS Cognito using SDK
  const signOutFromCognito = async (clientId) => {
    try {
      const cognitoIdentityServiceProvider =
        new AWS.CognitoIdentityServiceProvider();

      // Get current access token
      const accessToken = localStorage.getItem("accessToken");

      if (accessToken) {
        // Revoke the token if we have one
        const params = {
          Token: accessToken,
          ClientId: clientId,
        };

        await cognitoIdentityServiceProvider.revokeToken(params).promise();
        console.log("Successfully revoked token and signed out");
      }
    } catch (error) {
      console.error("Error signing out from Cognito:", error);
    }
  };

  // Function to clear all authentication related storage
  const clearAllAuthStorage = () => {
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("sessionUsername");
    localStorage.removeItem("UserTimeZone");
    localStorage.removeItem("UserClinic");
    localStorage.removeItem("UserParentClinic");
    // Clear any other auth-related items you might have
  };
  const getUserAttributes = async (accessToken) => {
    const params = {
      AccessToken: accessToken,
    };

    const cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider();

    try {
      const userData = await cognitoIdentityServiceProvider
        .getUser(params)
        .promise();
      const attributesObject = userData.UserAttributes.reduce(
        (acc, { Name, Value }) => {
          acc[Name] = Value;
          return acc;
        },
        {}
      );

      getTherapistByEmail(attributesObject);
      return attributesObject;
    } catch (error) {
      console.error("Error fetching user attributes:", error);
      return null;
    }
  };
  const getPatientIdByEmail = (email) => {
    var pageType = {
      pathname: `/add-patient`,
      state: {
        patDetails: {
          patfname: qPatDetails?.patfname,
          patlname: qPatDetails?.patlname,
          patemail: qPatDetails?.patemail,
          patphone: qPatDetails?.patphone,
          patmrn: qPatDetails?.patmrn,
        },
      },
    };
    var data = {};
    var headerdata = "";
    _callApi(
      data,
      "REACT_APP_PATIENT_DETAILS",
      headerdata,
      2,
      `?email=${encodeURIComponent(email)}`
    )
      .then((response) => {
        if (parseInt(response.status) === 200 && response?.data?.id) {
          let patClinicId = response?.data?.clinics[0];
          let hasClinic = clinicList?.find(
            (clinic) => clinic.clinicId === patClinicId
          );
          if (hasClinic) {
            dispatch(ActionCreators.setClinicBranch(hasClinic.clinicId));
            dispatch(ActionCreators.setClinicTZ(hasClinic.timezone));
            localStorage.setItem("UserTimeZone", hasClinic.timezone);
            localStorage.setItem("UserClinic", hasClinic.clinicId);
            history.push(`/patient-exercises/${response?.data?.id}/1`);
          } else {
            history.push(pageType);
          }
        } else {
          history.push(pageType);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const setRedirection = () => {
    if (clientRedirectTo === "patientregistration") {
      getPatientIdByEmail(qPatDetails?.patemail);
    } else {
      let pageType;
      pageType = {
        pathname: getDashboard(userRoles),
      };

      history.push(pageType);
    }
  };
  const setTherapistClinics = (clinicData, profile) => {
    setClinicList(clinicData);
    dispatch(ActionCreators.addClinicBranchs(clinicData));
    if (clinicData.length > 0) {
      dispatch(ActionCreators.setClinicBranch(clinicData[0].clinicId));
      dispatch(ActionCreators.setParentClinic(clinicData[0].parentClinicId));
      dispatch(ActionCreators.setClinicTZ(clinicData[0].timezone));
      localStorage.setItem("UserTimeZone", clinicData[0].timezone);
      localStorage.setItem("UserClinic", clinicData[0].clinicId);
      localStorage.setItem("UserParentClinic", clinicData[0].parentClinicId);
      getProviderRoles(
        clinicData[0].parentClinicId,
        clinicData[0].clinicId,
        profile,
        dispatch
      )
        .then(() => {
          dispatch(
            ActionCreators.setLoginTime(
              Moment(),
              clinicData[0].parentClinicId,
              uuid
            )
          );
          if (
            [...profile.profile.split(",")]
              .map((profile) => profile.trim())
              .includes("CARE_NAVIGATORS")
          ) {
            postSessionInfo(
              0,
              clinicData[0].parentClinicId,
              profile?.id,
              true,
              uuid
            );
          }
          if (
            [...profile.profile.split(",")]
              .map((profile) => profile.trim())
              .includes("CARE_NAVIGATORS")
          ) {
            dispatch(ActionCreators.setTimerOut(IDEAL_TIMEOUT_INIT));
          }

          // var pageType;
          // pageType = {
          //   pathname: getDashboard(userRoles),
          // };

          // history.push(pageType);
          if (clinicData.length === 1) {
            setRedirection();
          } else {
            openClinicSelectionModal();
            setRedirect(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const getTherapistByEmail = (search) => {
    var data = {};
    var headerdata = "";
    _callApi(
      data,
      "REACT_APP_GET_THERAPIST_URL",
      headerdata,
      2,
      "?email=" + encodeURIComponent(search.email)
    )
      .then((response) => {
        if (response) {
          if (response.status === 202) {
            var myPatientsData = response.data;

            if (myPatientsData.length > 0) {
              var userobject = search;
              userobject["id"] = myPatientsData[0].id;
              userobject["isAdmin"] =
                myPatientsData[0].enumerationType === "NPI-2" ? true : false;
              localStorage.getItem({ id: myPatientsData[0].id });
              dispatch(ActionCreators.addProfile(userobject));
              const userProfiles = [...userobject.profile.split(",")].map(
                (profile) => profile.trim()
              );

              if (
                ((userProfiles.includes("SYSTEM ADMINISTRATOR") ||
                  userProfiles.includes("SYSTEM_ADMINISTRATOR")) &&
                  ((userProfiles.includes("REPORT") &&
                    userProfiles.length === 2) ||
                    (!userProfiles.includes("REPORT") &&
                      userProfiles.length === 1))) ||
                ((userProfiles.includes("SYSTEM ADMINISTRATOR") ||
                  userProfiles.includes("SYSTEM_ADMINISTRATOR")) &&
                  userProfiles.includes("LOCAL_ADMIN") &&
                  ((userProfiles.includes("REPORT") &&
                    userProfiles.length === 3) ||
                    (!userProfiles.includes("REPORT") &&
                      userProfiles.length === 2))) ||
                (userProfiles.includes("LOCAL_ADMIN") &&
                  ((userProfiles.includes("REPORT") &&
                    userProfiles.length === 2) ||
                    (!userProfiles.includes("REPORT") &&
                      userProfiles.length === 1)))
              ) {
                dispatch(
                  ActionCreators.setParentClinic(
                    myPatientsData[0].parentClinicId
                  )
                );

                localStorage.setItem(
                  "UserParentClinic",
                  myPatientsData[0].parentClinicId
                );

                if (userProfiles.includes("REPORT")) {
                  dispatch(ActionCreators.setUserRoles(["report", "sysadmin"]));
                } else {
                  dispatch(ActionCreators.setUserRoles(["sysadmin", "report"]));
                }
                dispatch(
                  ActionCreators.setConfig("", myPatientsData[0].parentClinicId)
                );

                //for local admin
                if (myPatientsData[0].therapistClinics?.length > 0) {
                  dispatch(
                    ActionCreators.addClinicBranchs(
                      myPatientsData[0].therapistClinics
                    )
                  );

                  dispatch(
                    ActionCreators.setClinicTZ(
                      myPatientsData[0].therapistClinics?.[0].timezone
                    )
                  );
                  localStorage.setItem(
                    "UserTimeZone",
                    myPatientsData[0].therapistClinics?.[0].timezone
                  );
                }

                var pageType = {
                  pathname: "/admin-dashboard",
                };
                history.push(pageType);
              } else {
                if (myPatientsData[0].therapistClinics.length > 0) {
                  setTherapistClinics(
                    myPatientsData[0].therapistClinics,
                    userobject
                  );
                } else {
                  if (
                    ["SYSTEM ADMINISTRATOR", "SYSTEM_ADMINISTRATOR"].some((a) =>
                      userProfiles.includes(a)
                    ) &&
                    myPatientsData[0].parentClinicId !== null
                  ) {
                    dispatch(
                      ActionCreators.setParentClinic(
                        myPatientsData[0].parentClinicId
                      )
                    );

                    localStorage.setItem(
                      "UserParentClinic",
                      myPatientsData[0].parentClinicId
                    );

                    if (userProfiles.includes("REPORT")) {
                      dispatch(
                        ActionCreators.setUserRoles(["report", "sysadmin"])
                      );
                    } else {
                      dispatch(
                        ActionCreators.setUserRoles(["sysadmin", "report"])
                      );
                    }

                    dispatch(
                      ActionCreators.setConfig(
                        "",
                        myPatientsData[0].parentClinicId
                      )
                    );
                    let pageType = {
                      pathname: "/admin-dashboard",
                    };
                    history.push(pageType);
                  }
                }
              }
            }
          } else {
            let data = localStorage.getItem("browser-tab");
            localStorage.clear();
            localStorage.setItem("browser-tab", data);
          }
        }
      })
      .catch((error) => {
        console.log("Error fetching therapist by email:", error);
      });
  };
  return (
    <div>
      <Loader
        type="ThreeDots"
        color="var(--primaryColor)"
        height={40}
        width={40}
        className="graph-laoder"
      />
    </div>
  );
};

export default SelectClinicBranchwrapper(SSO);
