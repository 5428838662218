import axios from "axios";
import { Redirect } from "react-router-dom";
import { tokenRefresh } from "./authService";
// universal methode to call api
export function _callApi(
  data,
  url,
  headerdata = "",
  method = 1,
  querydata,
  headerDetails = ""
) {
  var result;
  var siteSetting = "";
  if (querydata && querydata.length > 0) {
    siteSetting = process.env[url] + querydata;
  } else {
    siteSetting = process.env[url];
  }
  let accessToken = localStorage.getItem("accessToken");

  if (headerdata === "") {
    headerdata = {
      Authorization: accessToken,
      crossDomain: true,
    };
  } else if (headerdata === "2") {
    headerdata = {
      Authorization: accessToken,
      "Content-Type": "multipart/form-data",
      crossDomain: true,
    };
  } else if (headerdata === "3") {
    headerdata = {
      Authorization: accessToken,
      "Content-Type": "application/json",
      crossDomain: true,
    };
  } else if (headerdata === "6") {
    headerdata = {
      Authorization: accessToken,
      crossDomain: true,
      profile: "REPORT",
    };
  } else if (headerdata === "7") {
    headerdata = {
      Authorization: headerDetails,
      crossDomain: true,
    };
  } else {
    headerdata = {
      crossDomain: true,
    };
  }
  const instance = axios.create({
    baseURL: siteSetting,
    timeout: 300000,
    headers: headerdata,
  });
  instance.interceptors.request.use((req) => {
    if (accessToken) {
      const splitToken = accessToken.split(".");
      const decodeToken = atob(splitToken?.[1]);
      const tokenObj = JSON.parse(decodeToken);
      const currentTime = Math.floor(Date.now() / 1000);
      if (tokenObj.exp - currentTime < 10 * 60) {
        // check auth token before 5 minutes (5*60) in secs
        return tokenRefresh()
          .then(() => {
            req.headers.Authorization = localStorage.getItem("accessToken");
            return req;
          })
          .catch((error) => {
            console.log("Error:", error);
            let data = localStorage.getItem("browser-tab");
            localStorage.clear();
            localStorage.setItem("browser-tab", data);
            window.location.href = "/";
            throw error;
          });
      }
    }

    return req;
  });

  if (method === 1) {
    // if method post
    result = instance
      .post("", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error && error.response && error.response.status) {
          var newData = {
            msg: error.toString(),
            data: error.response.data,
            status: error.response.status,
          };
          return newData;
        }
        // if (
        //   error &&
        //   error?.response &&
        //   parseInt(error?.response?.status) === 401
        // ) {
        //   let data = localStorage.getItem("browser-tab");
        //   localStorage.clear();
        //   localStorage.setItem("browser-tab", data);
        //   window.location.href = "/";
        //   return false;
        // } else {
        //   if (error && error.response && error.response.status) {
        //     var newData = {
        //       msg: error.toString(),
        //       data: error.response.data,
        //       status: error.response.status,
        //     };
        //     return newData;
        //   }
        // }
      });
  }
  if (method === 3) {
    // if method post
    result = instance
      .put("", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error && error.response && error.response.status) {
          var newData = {
            msg: error.toString(),
            data: error.response.data,
            status: error.response.status,
          };
          return newData;
        }
        // if (
        //   error &&
        //   error.response &&
        //   parseInt(error?.response?.status) === 401
        // ) {
        //   let data = localStorage.getItem("browser-tab");
        //   localStorage.clear();
        //   localStorage.setItem("browser-tab", data);
        //   window.location.href = "/?sessionExpired=true";
        //   return false;
        // } else {
        //   if (error && error.response && error.response.status) {
        //     var newData = {
        //       msg: error.toString(),
        //       data: error.response.data,
        //       status: error.response.status,
        //     };
        //     return newData;
        //   }
        // }
      });
  }
  if (method === 2) {
    // if method get

    result = instance
      .get("", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (
          error &&
          error?.response &&
          parseInt(error?.response?.status) === 401
        ) {
          if (parseInt(error?.response?.status) === 401) {
            let data = localStorage.getItem("browser-tab");
            localStorage.clear();
            localStorage.setItem("browser-tab", data);
            // window.location.reload();
            return <Redirect to={{ pathname: "/#/login" }} />;
            //window.location.href = "/?sessionExpired=true";
            //return false;
          } else {
            var newData = {
              msg: error?.toString(),
              data: error?.response?.data || null,
              status: error?.response?.status || 400,
            };
            return newData;
          }
        } else if (
          error &&
          error?.response &&
          parseInt(error?.response?.status) === 400
        ) {
          return error?.response?.data || null;
        } else {
          let newData = {
            msg: error?.toString(),
            data: error?.response?.data || null,
            status: error?.response?.status || 400,
          };
          return newData;
        }
      });
  }
  if (method === 4) {
    // if method delete
    result = instance
      .delete("", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error && error?.response && error?.response?.status) {
          var newData = {
            msg: error?.toString(),
            data: error?.response?.data || null,
            status: error?.response?.status || 400,
          };
          return newData;
        }
        // if (
        //   error &&
        //   error.response &&
        //   parseInt(error?.response?.status) === 401
        // ) {
        //   let data = localStorage.getItem("browser-tab");
        //   localStorage.clear();
        //   localStorage.setItem("browser-tab", data);
        //   window.location.href = "/?sessionExpired=true";
        //   return false;
        // } else {
        //   if (error && error?.response && error?.response?.status) {
        //     var newData = {
        //       msg: error?.toString(),
        //       data: error?.response?.data || null,
        //       status: error?.response?.status || 400,
        //     };
        //     return newData;
        //   }
        // }
      });
  }
  if (method === 5) {
    // if method patch
    result = instance
      .patch("", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error && error?.response && error?.response?.status) {
          var newData = {
            msg: error?.toString(),
            data: error?.response?.data || null,
            status: error?.response?.status || 400,
          };
          return newData;
        }
        // if (
        //   error &&
        //   error.response &&
        //   parseInt(error?.response?.status) === 401
        // ) {
        //   let data = localStorage.getItem("browser-tab");
        //   localStorage.clear();
        //   localStorage.setItem("browser-tab", data);
        //   window.location.href = "/?sessionExpired=true";
        //   return false;
        // } else {
        //   if (error && error?.response && error?.response?.status) {
        //     var newData = {
        //       msg: error?.toString(),
        //       data: error?.response?.data || null,
        //       status: error?.response?.status || 400,
        //     };
        //     return newData;
        //   }
        // }
      });
  }
  return result;
}
