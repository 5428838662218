import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
const SelectClinicBranchModal = (props) => {
  const {
    showHide,
    messageConfirm,
    handleBranchConfirm,
    handleOnChangeBranch,
    checked,
    clinics,
    fetchMoreclinics,
    searchClinic,
    searchClinicVal,
  } = props;

  return (
    <Modal
      className="form branchPopup"
      show={showHide}
      //   onHide={}
      centered
      backdropClassName="branchPopup--backdrop"
    >
      <Modal.Header>
        <h2> Choose your Clinic location</h2>
      </Modal.Header>
      <Modal.Body>
        <Container className=" ">
          {messageConfirm.length > 0 ? (
            <div
              className="alert alert-danger"
              role="alert"
              id={`alert_scbm_top`}
            >
              {messageConfirm}
            </div>
          ) : (
            ""
          )}
          <Row>
            <Col>
              <div>
                <div
                  style={{
                    position: "relative",
                    top: "4px",
                    left: "10px",
                    height: "0px",
                  }}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.75 15.75L12.3855 12.3795M14.25 7.875C14.25 9.56576 13.5784 11.1873 12.3828 12.3828C11.1873 13.5784 9.56576 14.25 7.875 14.25C6.18424 14.25 4.56274 13.5784 3.36719 12.3828C2.17165 11.1873 1.5 9.56576 1.5 7.875C1.5 6.18424 2.17165 4.56274 3.36719 3.36719C4.56274 2.17165 6.18424 1.5 7.875 1.5C9.56576 1.5 11.1873 2.17165 12.3828 3.36719C13.5784 4.56274 14.25 6.18424 14.25 7.875V7.875Z"
                      stroke="#C4C4C4"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <input
                  placeholder="Search for Clinic..."
                  className="form-control search-clinic"
                  type="text"
                  name="search"
                  id="search"
                  onChange={searchClinic}
                  value={searchClinicVal}
                  style={{
                    padding: "12px 0px 12px 34px !important",
                  }}
                />
              </div>
              <div
                className="clinics-scrollbar"
                style={{}}
                id="scrollableacDiv"
              >
                {clinics.isLoading ? (
                  <>
                    <Loader
                      type="ThreeDots"
                      color="var(--primaryColor)"
                      height={10}
                      width={40}
                      style={{
                        textAlign: "center",
                        paddingTop: "0px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <InfiniteScroll
                      dataLength={clinics.data.length}
                      next={fetchMoreclinics}
                      hasMore={clinics.hasMore}
                      loader={<span>Loading...</span>}
                      scrollableTarget="scrollableacDiv"
                      className="clinics-scrollbar-infi"
                    >
                      {clinics.data.length > 0 ? (
                        clinics.data.map((branch, index) => {
                          return (
                            <Row
                              className="branchPopup--radiobtn"
                              key={index}
                              onClick={() =>
                                handleOnChangeBranch({
                                  target: { value: branch.clinicId },
                                })
                              }
                            >
                              <Col>
                                <input
                                  id="radio-improving"
                                  type="radio"
                                  onChange={(e) => handleOnChangeBranch(e)}
                                  checked={checked === branch.clinicId}
                                  value={branch.clinicId}
                                />
                                <span
                                  className="branchPopup--radiobtn-span"
                                  title={
                                    branch.clinicName.length > 55
                                      ? branch.clinicName
                                      : ""
                                  }
                                  onClick={() =>
                                    handleOnChangeBranch({
                                      target: { value: branch.clinicId },
                                    })
                                  }
                                  style={{ cursor: "default" }}
                                >
                                  {branch.clinicName}
                                </span>
                              </Col>
                            </Row>
                          );
                        })
                      ) : (
                        <div>No Clinic Found</div>
                      )}
                    </InfiniteScroll>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="branchPopup--btn">
              <button
                type="button"
                className="btn btn-primary "
                onClick={handleBranchConfirm}
                id={`confirmBtn`}
              >
                Confirm
              </button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SelectClinicBranchModal;
